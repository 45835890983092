module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"I-am-yukuri","short_name":"yukuri","start_url":"/","background_color":"#C2A2C8","theme_color":"#C2A2C8","display":"minimal-ui","icon":"src/images/imagotipo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"10f0d6300bdfc71e72150eff9de4cf7c"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"yukuri","accessToken":"MC5YcDNrSHhJQUFDRUF5czcw.77-9fe-_vSBZU2FdXw3vv70Ufe-_ve-_ve-_vWUUVe-_vR3vv73vv73vv71BZu-_vVgAAEzvv70","schemas":{"gallery":{"Main":{"picture":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"picture"}}}},"landing":{"Main":{"mainpicture":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"MainPicture"}},"title":{"type":"StructuredText","config":{"single":"heading2, heading3, heading4, heading5, heading6","label":"title"}},"description":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"description"}}}},"img":{"Main":{"pic":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"pic"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
